import request from "@/utils/request.js";
import {isDev} from "@/setting.js";

const UrlPrefix= isDev ? "/Qilin" : "";

// 单点登陆解析x-token接口
export function analysisToken(params){
    return request({
        url:UrlPrefix+"/oaSsoOauth",
        method:"get",
        params
    });
}

// 登录接口
export function loginIn(params){
    return request({
        url:UrlPrefix+"/login",
        method:"post",
        data:params
    });
}

// 查询部门下拉树结构
export function deptTreeSelect() {
    return request({
    // header:{
    //     Authorization:sessionStorage.getItem('token')
    // },
      url:UrlPrefix+'/system/user/deptTree',
      method: 'get'
    })
  }

// 新增人员信息
export function addUser(data) {
    return request({
      url:UrlPrefix+'/system/user',
      method: 'post',
      data: data
    })
  }

// 获取人员列表
export function listUser(query) {
  return request({
    url:UrlPrefix+'/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url:UrlPrefix+'/system/user/'+userId,
    method: 'get'
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url:UrlPrefix+'/system/user/profile',
    method: 'get'
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url:UrlPrefix+'/system/user',
    method: 'put',
    data: data
  })
}

// 删除人员
export function deleteUser(userId) {
  return request({
    url:UrlPrefix+'/system/user/'+userId,
    method: 'delete',

  })
}


// 查询职位下拉树结构
export function postSelect() {
  return request({
    url: UrlPrefix+'/system/user/',
    method: 'get',
  })
}

//下载模板
export function getTemplate(){
  return request({
    url:UrlPrefix+"/system/user/importTemplate",
    method:"post",
    responseType:"blob"
  })
}

//导出表格
export function exportTable(commonParam){
  return request({
    url:UrlPrefix+"/system/user/export",
    method:"post",
    responseType:"blob",
    data: commonParam
  })
}

// 导入excel文档接口
export function importExcelData(params){
  return request({
      url:UrlPrefix+"/system/user/importData",
      method:"post",
      data:params,
      // timeout: 30000,
      timeout: 120000,
  });
}
//查询接口
export function searchData(query){
  return request({
      url:UrlPrefix+"/system/user/list",
      method:"get",
      params: query
    })
  }

// 发送验证码接口
export function sendCode(query){
  return request({
    // url:"http://47.122.0.182:8084/sendCode",
    url:UrlPrefix+"/sendCode",
    method:"get",
    params: query
  })
}

// 验证码登录接口
export function codeLogin(query){
  return request({
    url:UrlPrefix+"/codeLogin",
    method:"get",
    params: query
  })
}

// 用户密码重置
export function updateUserPwd(query) {
  return request({
    url: UrlPrefix+'/system/user/profile/updatePwd',
    method: 'put',
    params: query
  })
}

// 用户第一次登录强制修改密码
export function firstUpdatePwd(query) {
  return request({
    url: UrlPrefix+'/system/user/profile/firstUpdatePwd',
    method: 'put',
    params: query
  })
}

// 管理员重置密码接口
export function resetPwd(query) {
  // console.log(query)
  return request({
    url: UrlPrefix+'/system/user/resetPwd',
    method: 'put',
    data: query
  })
}

// 忘记密码发送验证码接口
export function sendCodes(query){
  return request({
    url:UrlPrefix+"/resetPwd/sendCode",
    method:"get",
    params: query
  })
}

// 忘记密码确认重置密码
export function forgetPwd(query){
  return request({
    url:UrlPrefix+"system/user/codeResetPwd",
    method:"get",
    params:query
  })
}