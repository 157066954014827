<template>
  <div class="retrievePassword">
    <div class="mainbody">
      <div class="box">
        <el-form ref="passwords" :model="passwords" :rules="rules" class="form">
        <p>忘记密码</p>
        <el-row>
          <el-col :span="12" :offset="6">
            <el-form-item label="手机号:" prop="telephone" :inline="true">
              <el-input class="telephone" type="tel" style="width:310px" v-model="passwords.telephone" placeholder="请输入手机号" maxlength="11"
                @blur="changePhone($event)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="6">
            <el-form-item label="验证码:" prop="send" :inline="true">
              <el-input type="text" tabindex="2" style="width:190px" v-model="passwords.send" placeholder="请输入验证码">
              </el-input>
              <el-button class="send" :disabled="disabled" @click="sendCodes(passwords.telephone)">{{ btntext }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="6">
            <el-form-item label="新密码:" prop="newPassword" :inline="true">
              <el-input show-password style="width:310px" v-model="passwords.newPassword" placeholder="6-20位字符，包含英文字母，数字" maxlength="30"
                type="password" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="6">
            <el-form-item label="确认新密码:" prop="newPasswords" :inline="true">
              <el-input show-password style="width:310px" v-model="passwords.newPasswords" placeholder="6-20位字符，包含英文字母，数字" maxlength="30"
                type="password" />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="11">
            <el-button type="primary" @click="changeForm()" size="medium" style="margin-bottom:20px">确
              定</el-button>
          </el-col>
        </el-row>
      </el-form>
      </div>
      <!-- <el-form ref="passwords" :model="passwords" :rules="rules" class="form"> 
        <p>忘记密码</p>
        <el-row>
          <el-col :span="14" :offset="8">
            <el-form-item label="手机号 " prop="telephone" :inline="true">
              <el-input class="telephone" type="tel" style="width:300px" v-model="passwords.telephone" placeholder="请输入手机号" maxlength="11"
                @blur="changePhone($event)">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14" :offset="8">
            <el-form-item label="验证码 " prop="send" :inline="true">
              <el-input type="text" tabindex="2" style="width:190px" v-model="passwords.send" placeholder="请输入验证码">
              </el-input>
              <el-button class="send" :disabled="disabled" @click="sendCodes(passwords.telephone)">{{ btntext }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="14" :offset="8">
            <el-form-item label="新密码 " prop="newPassword" :inline="true">
              <el-input show-password style="width:300px" v-model="passwords.newPassword" placeholder="  请输入新密码" maxlength="30"
                type="password" />
            </el-form-item>
          </el-col>
          <el-col :span="14" :offset="8">
            <el-form-item label="确认密码" prop="newPasswords" :inline="true">
              <el-input show-password style="width:300px" v-model="passwords.newPasswords" placeholder="  请再次输入新密码" maxlength="30"
                type="password" />
            </el-form-item>
          </el-col>
          <el-col :span="14" :offset="11">
            <el-button type="primary" @click="changeForm()" size="medium" style="margin-bottom:20px">确
              定</el-button>
          </el-col>
        </el-row>
      </el-form> -->
    </div>
  </div>
</template>

<script>
import { sendCodes, forgetPwd } from "../api/index.js";

export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.passwords.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      passwords: {
        telephone: '',
        send: '',
        newPassword: '',
        newPasswords: '',
      },
      // 按钮状态
      disabled: false,
      // 按钮文字
      nums: 60,
      btntext: "获取验证码",
      rules: {
        telephone: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur"
          }
        ],
        send: [
          {
            required: true,
            message: "验证码不能为空",
            trigger: "blur"
          }
        ],
        newPassword: [
          {
            pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,20}$/,
            required: true,
            message: "新密码不能为空,长度为 6 到 20 个字符，数字字母组合",
            trigger: "blur"
          },
        ],
        newPasswords: [
          { required: true, validator: equalToPassword, trigger: "blur" }
        ],
      }
    }
  },
  methods: {
    // 返回
    returnbtn(){
      this.reset();
      // this.$router.push( '/mylogin' )
      window.opener = null;
      window.open("about:blank", "_top").close()
    },
    // 手机号校验
    changePhone(e) {
      this.checkMobile(e.target.value);
    },
    checkMobile(value) {
      const regMobile =
        /^(13[0-9]|15[0123456789]|17[678]|18[0-9]|19[89]|14[57])[0-9]{8}$/;
      if (regMobile.test(value) == true) {
        // this.$message({
        //   message: "手机号符合要求",
        //   type: "success",
        // });
      } else {
        this.$message({
          message: "请输入合法的手机号",
          type: "error",
        });
      }
    },
    // 验证码
    sendCodes(phone) {
      if (phone) {
        sendCodes({ phone: phone })
          .then((response) => {
            console.log("response", response);
            if (response.code === 200) {
              this.$message({
                message: response.msg,
                type: "success",
              });
              this.disabled = true;
              var timer = setInterval(() => {
                console.log(this.nums);
                this.nums--;
                this.btntext = this.nums + "秒后可重新发送";
                if (this.nums <= 0) {
                  this.btntext = "获取验证码";
                  this.disabled = false;
                  this.nums = 60;
                  clearInterval(timer);
                }
              }, 1000);
            } else {
              this.$message({
                message: response.msg,
                type: "error",
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        // this.$message({
        //     message: "手机号不能为空",
        //     type: "error",
        //   });
      }
    },
    reset() {
      this.passwords = {
        telephone: '',
        send: '',
        newPassword: '',
        newPasswords: '',
      }
    },
    changeForm() {
      this.$refs["passwords"].validate(valid => {
        if (valid) {
          forgetPwd({phone: this.passwords.telephone, password: this.passwords.newPassword, repeatPwd:this.passwords.newPasswords, code: this.passwords.send,}).then(response => {
            if (response.code === 200) {
              this.$message({
                message: "密码修改成功",
                type: "success",
              });
              this.reset();
              setTimeout(() => {
                // this.$router.push("/mylogin");
                window.opener = null;
                window.open("about:blank", "_top").close()
              }, 1000);
            } else {
              this.$message({
                message: response.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.retrievePassword{
  height: 100%;
  width: 100%;
}
.mainbody {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: #fff;
  min-width: 700px;
  background: url('../assets/images/forgetpwd.png') no-repeat;
  background-size: cover;
}
.mainbody>.box{
  width: 1000px;
  height: 460px;
  display: flex;
  margin: auto;
  margin-top: 10%;
  justify-content: center;
  background-color: #F7F9FE;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
  border-radius: 4px 4px 4px 4px;
}
.mainbody>.box>.form>p{
  text-align: center;
  padding: 25px 0 17px 0;
  font-size: 24px;
  font-family: Source Han Sans SC-Medium,Source Han Sans SC;
  line-height: 28px;
  font-weight: 500;
  color: #148EFF;
  border-bottom: 1px solid #D9D9D9;
  margin: 0 33px;
}
::v-deep .el-form .el-form-item__label{
  width: 100px;
}
::v-deep .el-row{
  padding-top: 64px;
  min-width: 950px;
}
.send{
  margin-left: 10px;
  border-color: #148EFF;
  color: #148EFF;

}
</style>
