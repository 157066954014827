import Vue from "vue";
import Vuex from "vuex";

import {getUserInfoByToken,syncUserInfoData} from "@/api/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        userInfo:{} //当前用户信息
    },  
    getters:{
        userInfo(state){
            return state.userInfo;
        }
    },
    mutations:{
        set_userInfo(state,userInfo){
            if(userInfo){
                state.userInfo=userInfo;
            }else{
                state.userInfo={};
            }
        }
    },
    actions:{
        // 获取用户信息
        // async setUserInfo({commit},params){
        //     return await getUserInfoByToken(params).then((res)=>{
        //         if(res.code === 200){
        //             commit("set_userInfo",res.result);
        //             return true;
        //         }else{
        //             return false;
        //         }
        //     });
        // },
        // 同步用户信息
        // async syncUserInfoData(){
        //     return await syncUserInfoData().then((res)=>{
        //         if(res.code === 200){
        //             return res;
        //         }else{
        //             return false;
        //         }
        //     });
        // }
    },
    modules:{}
});
