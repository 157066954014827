<template>
    <div id="app">
        <!-- <keep-alive> -->
            <router-view></router-view>
        <!-- </keep-alive> -->
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods:{

    },
    mounted(){
        // this.$store.dispatch("setUserInfo",{}).then((flag)=>{
        //     // console.log(flag);
        //     if(!flag){
        //         // console.log("如果无权限，将跳转至新的页面");
        //         this.$router.push({
        //             name:"noAuthentification"
        //         });
        //     }
        // });
    }
}
</script>

<style lang="scss">
html{
    font-size:87.5%; //浏览器默认大小为16px，本项目设置根元素默认字体大小为14px
}
html,body,#app{
    @include setWH;
    color:$color-text-common;
    font-family:$text-china-default,$text-english-default;
    background-color:$color-background-common;
    // overflow: hidden;
}
*{
    @include general;
    font-family: Source Han Sans CN-Regular;
}
</style>
