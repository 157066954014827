import axios from "axios";
import {Message} from "element-ui";
// import router from "@/router/index.js";
import {baseUrl} from "@/setting.js";
import router from "../router/index.js";
import {getValueCookie,deleteValueCookie} from "@/utils/cookie.js";

// let loading;
// function startLoading(){
//     loading=Loading.service({
//         lock:true,
//         text:"请求加载中...",
//         background:"rgba(0,0,0,0.7)"
//     });
// }
// function endLoading(){
//     loading.close();
// }

const service=axios.create({
    baseURL:baseUrl,
    timeout:10*1000
});

// 根据特定的key值查询cookie中的value
// export function getValueCookie(keyName){
//     const Cookies=document.cookie;
//     const startIndex=Cookies.indexOf(keyName);
//     if(startIndex !== -1){
//         let startValueIndex=startIndex+(keyName.length+1);
//         let endIndex=Cookies.indexOf(";",startValueIndex);
//         if(endIndex ===-1){
//             endIndex=Cookies.length;
//         }
//         let value=decodeURI(Cookies.substring(startValueIndex,endIndex));
//         return value;
//     }else{
//         return null;
//     }
// }
// // getcookie
// export function getCookie(name) {
//         var strCookie=document.cookie;
//         var arrCookie=strCookie.split(";");
//         for(var i=0;i<arrCookie.length;i++){
//                 if (arrCookie[i].indexOf(" ") == 0) {
//                        arrCookie[i] = arrCookie[i].substring(1);
//                     }
//             var c=arrCookie[0].split("=");
//             if(c[0]==name){
//                 return c[1];
//             }
//         }
//         return "";
//     }
// Cookie
// export function setCookie(cname,cavlue,day){
//     var d = new Date()
//     d.setTime(d.getTime()+(day*24*60*60*1000))
//     document.cookie = cname+"="+cavlue+"; expires="+d.toUTCString()+";path=/";
//     return document.cookie
// }
// 请求拦截
service.interceptors.request.use((requestConfig)=>{
    // console.log(requestConfig); //请求配置项
    // startLoading();
    // requestConfig.headers["cas-token"]=getValueCookie("x-token");
    // requestConfig.headers["Authorization"]="Bearer "+sessionStorage.getItem('token');
    requestConfig.headers["Authorization"]="Bearer "+getValueCookie("TX-token");
    requestConfig.headers["Content-Type"]="application/json";
    return requestConfig;
},(error)=>{
    return Promise.reject(error);
});

// 响应拦截
service.interceptors.response.use((response)=>{
    // console.log(response); //响应数据体
    // endLoading();
    if(response.data.code === 401 || response.data.code === 403 ) {
		// 登陆超时 - 401 ||
		// var msg = '登陆超时'
		// Message({
		// 	message: msg,
		// 	type: 'error',
		// 	duration: 1 * 1000
		// })
		router.push('/myLogin')
		// 清除浏览器缓存
		localStorage.removeItem('token')
        deleteValueCookie("TX-token");
        deleteValueCookie("RANK_TOKEN");
        deleteValueCookie("x-token");
	} else {
		// 响应正常，返回数据即可
		return response.data;
	}
    // return response.data;
},(error)=>{
    // console.log(error,error.response);
    // endLoading();
    if(error.message.includes("timeout")){
        Message.error({
            message:"请求超时",
            center:true
        });
    }else{
        Message.error({
            message:"系统错误",
            center:true
        });
    }
    return Promise.reject(error);
});

export default service;


