<template>
    <div class="big">
        <div class="leftpic">
            <div class="logo">
                <img src="../assets/images/通讯录登录页logo@2x.png" alt="">
            </div>
            <div class="words">
                让&nbsp;沟&nbsp;通，更&nbsp;近&nbsp;一&nbsp;点
            </div>
            <div class="versionNo">
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">苏ICP备2022032515号-2</a>
            </div>
        </div>
        <div class="right">
            <div class="midd">
                <div class="title">
                    欢迎来到U创通讯系统
                </div>
                <!-- <div class="logins">
          <span>账号登录</span>
        </div>
        <div class="userName">
          <i class="el-icon-user"></i>
          <input type="text" name="" id="" v-model="personal.accountnumber" placeholder="请输入账号" maxlength="11">
        </div>
        <div class="password">
          <i class="el-icon-lock"></i>
          <input type="password" v-model="personal.password" placeholder="请输入密码">
        </div>
        <div class="logon" @click="login" v-loading="loading" element-loading-spinner="el-icon-loading">
          登&nbsp;&nbsp;录
        </div> -->
                <el-tabs v-model="activeName">
                    <el-tab-pane label="密码登录" name="first" class="tab1">
                        <div class="userName">
                            <i class="el-icon-user"></i>
                            <input type="text" v-model="personal.accountnumber" placeholder="请输入手机号" maxlength="11">
                        </div>
                        <div class="password">
                            <i class="el-icon-lock"></i>
                            <input type="password" v-model="personal.password" placeholder="请输入密码">
                        </div>
                        <div class="tips">
                            <el-link @click="retrievePWD">忘记密码？</el-link>
                        </div>
                        <div class="logon" @click="login" v-loading="loading" element-loading-spinner="el-icon-loading">
                            登&nbsp;&nbsp;录
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="验证码登录" name="second" class="tab2">
                        <div class="userName">
                            <!-- <i class="el-icon-phone"></i> -->
                            <i class="icon-phone"></i>
                            <input class="telephone" type="tel" v-model="phoneCode.phone" placeholder="请输入手机号"
                                maxlength="11" @blur="changePhone($event)">
                        </div>
                        <div class="password">
                            <i class="el-icon-lock"></i>
                            <input type="text" v-model="phoneCode.code" placeholder="请输入验证码">
                            <el-button class="send" :disabled="disabled" @click="sendCode(phoneCode.phone)">{{ btntext }}
                            </el-button>
                        </div>
                        <div class="tips">
                            <el-link @click="retrievePWD">忘记密码？</el-link>
                        </div>
                        <div class="logon" @click="authLog" v-loading="loading" element-loading-spinner="el-icon-loading">
                            登&nbsp;&nbsp;录
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </div>
        </div>
    </div>
</template>

<script>
import service from "@/api/request";
import { setCookie } from "@/utils/request";
import { sendCode, codeLogin, loginIn } from "../api/index.js";
import { setValueCookie, getValueCookie } from "@/utils/cookie.js";
export default {
    data() {
        var checkMobile = (rules, value, callback) => {
            const regMobile =
                /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
            if (regMobile.test(value) == true) {
                return callback();
            } else {
                callback(new Error("请输入合法的手机号"));
            }
        };
        return {
            activeName: "first",
            loading: "",
            nums: 60,
            // 账号密码
            personal: {
                accountnumber: "",
                password: "",
            },
            btntext: "获取验证码",
            // 手机号验证码
            phoneCode: {
                phone: "",
                code: "",
            },
            rules: {
                // accountnumber: [
                //   { required: true, message: '请输入账号', trigger: 'blur' },
                //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                // ]
            },
            disabled: false,
        };
    },
    methods: {
        changePhone(e) {
            console.log("00", e);
            console.log("000", e.target.value);
            this.checkMobile(e.target.value);
        },
        checkMobile(value) {
            const regMobile =
                /^(13[0-9]|15[0123456789]|17[678]|18[0-9]|19[89]|14[57])[0-9]{8}$/;
            if (regMobile.test(value) == true) {
                // this.$message({
                //   message: "手机号符合要求",
                //   type: "success",
                // });
            } else {
                // this.$message({
                //     message: "请输入合法的手机号",
                //     type: "error",
                // });
            }
        },
        // 登录1
        login() {
            this.loading = true;
            loginIn({
                username: this.personal.accountnumber,
                password: this.personal.password,
               }).then((res) => {
                if (res.code === 200) {
                    // if (res.code === 200) {
                    this.loading = false;
                    this.$message({
                        showClose: true,
                        message: "恭喜您，登录成功",
                        type: "success",
                    });
                    setValueCookie("TX-token", res.token);
                    setValueCookie("x-token", res["x-token"]);
                    sessionStorage.setItem("token", res.token);
                    sessionStorage.setItem("isAdmin", res.isAdmin);
                    if (res.firstLogin == 0) {
                        this.$router.push("/passChange");
                    } else {
                        this.$router.push("/index");
                    }
                } else {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: "error",
                    });
                    this.personal.password = "";
                    this.loading = false;
                }
            }).catch((err) => {
                console.log(err);
                this.$message({
                    showClose: true,
                    message: "系统错误",
                    type: "error",
                });
                this.loading = false;
            });
        },
        // 验证码
        sendCode(phone) {
            if (phone) {
                sendCode({ phone: phone })
                    .then((response) => {
                        if (response.code === 200) {
                            this.$message({
                                message: response.msg,
                                type: "success",
                            });
                            console.log(111);
                            this.disabled = true;
                            var timer = setInterval(() => {
                                console.log(this.nums);
                                this.nums--;
                                this.btntext = this.nums + "秒后可重新发送";
                                if (this.nums <= 0) {
                                    this.btntext = "获取验证码";
                                    this.disabled = false;
                                    clearInterval(timer);
                                }
                            }, 1000);
                        } else {
                            this.$message({
                                message: response.msg,
                                type: "error",
                            });
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                this.$message({
                    message: "手机号不能为空",
                    type: "error",
                  });
            }
        },

        // 登录2
        authLog() {
            this.loading = true;
            codeLogin(this.phoneCode).then((response) => {
                if (response.code === 200) {
                    // alert("修改成功");
                    this.loading = false;
                    this.$message({
                        message: "登录成功",
                        type: "success",
                    });
                    setValueCookie("TX-token", response.data.token);
                    setValueCookie("x-token", response.data["x-otken"]);
                    sessionStorage.setItem("token", response.data.token);
                    sessionStorage.setItem("isAdmin", response.data.isAdmin);
                    console.log("firstLogin", response);
                    console.log("firstLogin", response.data.firstLogin);
                    // if (response.data.firstLogin == 0) {
                    //     this.$router.push("/passChange");
                    // } else {
                    //     this.$router.push("/index");
                    // }
                    this.$router.push("/index");
                } else {
                    this.$message({
                        message: response.msg,
                        type: "error",
                    });
                    this.loading = false;
                }
            });
        },

        // 忘记密码
        retrievePWD() {
            // this.$router.push('/retrievePassword')
            const { href } = this.$router.resolve({
                path: '/retrievePassword'
            });
            window.open(href, '_blank');
        }
    },
};
</script>
<style scoped>
/* *{
  padding:0;
  margin:0;
  box-sizing:border-box;
} */
a {
    color: #fff;
    outline: none;
    text-decoration: none;
}

.big {
    width: 100%;
    height: 100vh;
    /* background-image: url(../assets/imgs/u=847811969\,3976990924&fm=253&fmt=auto&app=120&f=JPEG.webp); */
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.big>.leftpic {
    width: 37%;
    height: 100vh;
    /* background-color: red; */
    background-image: url(../assets/images/Group\ 8562.png);
    background-size: 100% 100%;
    position: relative;
}

.big>.leftpic>.logo {
    width: 211px;
    height: 52px;
    position: absolute;
    top: 72px;
    left: 64px;
}

.big>.leftpic>.logo>img {
    width: 100%;
    height: 100%;
    float: left;
}

.big>.leftpic>.words {
    width: 246px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 23px;
    color: #fff;
    position: absolute;
    top: 325px;
    left: 64px;
}

.big>.leftpic>.versionNo {
    /* width: 172px; */
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 50px;
    left: 64px;
    opacity: 0.5;
    font-weight: 100;
}

.big>.leftpic>.versionNo>a {
    font-weight: 300;
}

.big>.right {
    width: 63%;
    height: 100vh;
    position: relative;
    /* background-color: red; */
}

.big>.right>.midd {
    width: 440px;
    height: 400px;
    /* background-color: red; */
    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 260px);
}

.big>.right>.midd>.title {
    width: 100%;
    height: 39px;
    line-height: 39px;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    color: #262626;
    letter-spacing: 1px;
    margin-bottom: 36px;
}

.big>.right>.midd>.logins {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-top: 64px;
    margin-bottom: 30px;
    letter-spacing: 0.8px;
}

.big>.right>.midd>.logins>span {
    font-size: 22px;
    color: #000000 85%;
    font-family: Source Han Sans CN-Medium;
}

::v-deep .el-tabs__item {
    font-size: 22px !important;
}

.userName {
    width: 100%;
    height: 52px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 30px;
}

.userName>i {
    margin-left: 12px;
    font-size: 24px;
    line-height: 52px;
    color: #2b63ca;
    float: left;
}

.userName>.icon-phone {
    position: absolute;
    left: 0;
    z-index: 5;
    margin-top: 14px;
    margin-left: 16px;
    background-image: url(../assets/images/tel24.png);
    /*引入图片图片*/
    background-repeat: no-repeat;
    /*设置图片不重复*/
    background-position: 0px 0px;
    /*图片显示的位置*/
    width: 30px;
    /*设置图片显示的宽*/
    height: 30px;
    /*图片显示的高*/
}

.userName>input {
    width: 372px;
    height: 52px;
    line-height: 53px;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 12px;
    float: left;
}

.userName>.telephone {
    margin-left: 48px;
}

.password {
    width: 100%;
    height: 52px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-top: 24px;
    display: flex;
}

.password>input {
    flex: 1;
    height: 52px;
    line-height: 53px;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 12px;
    float: left;
}

.password>i {
    margin-left: 14px;
    font-size: 24px;
    line-height: 52px;
    color: #2b63ca;
    float: left;
}

.password>.el-button {
    width: 160px;
    border-width: 0;
    border-left: 1px solid #dcdfe6;
}

.tips{
    margin-top: 10px;
}

.big>.right>.midd .logon {
    width: 440px;
    height: 52px;
    text-align: center;
    border-radius: 52px;
    background: linear-gradient(176deg, #0954cc 0%, #2773ed 100%);
    line-height: 52px;
    font-size: 22px;
    color: #fff;
    margin-top: 48px;
    cursor: pointer;
}

.big>.right>.midd>.forget {
    width: 90px;
    height: 23px;
    /* background-color: red; */
    margin-top: 15px;
    font-size: 18px;
    color: #047ceb;
}</style>
