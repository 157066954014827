import axios from "axios"
// 拦截器  看接口传的参数符不符合要求  比如有没有token  有没有加密 MD5 sm2 sm3 sm4
// 请求拦截和响应拦截   参数是否为json格式  是否加密

// 请求拦截  axios.interceptors
// axios.defaults.baseURL = "http://192.168.10.35:8080"  //公共部分
import {baseUrl,isDev} from "@/setting.js";
const service=axios.create({
// baseURL:isDev ? "http://192.168.10.35:8096" : baseUrl,
baseURL:isDev ? "http://47.122.0.182:8096" : baseUrl,
timeout:30*1000
    });
// let baseURL = process.env.NODE_ENV==="development"?"/api":"http://192.168.10.35:8080"
// axios.defaults.baseURL = baseURL
// 请求拦截
service.interceptors.request.use(config => {
    console.log(config, "请求拦截");
    return config

}, err => {
    console.log(err);
})

// 响应拦截
service.interceptors.response.use(response => {
    console.log(response, "响应拦截");
    response = response.data
    return response
}, err => {
    console.log(err);
})

export default service
