<template>
    <div class="changepage">
        <div class="mainbody">
            <el-form ref="passwords" :model="passwords" :rules="rules">
                <el-row>
                    <el-col :span="14" :offset="1">
                        <el-form-item label="原密码" prop="oldPassword">
                            <el-input show-password v-model="passwords.oldPassword" placeholder="  请输入原密码" maxlength="30"
                                type="password"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="14" :offset="1">
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input show-password v-model="passwords.newPassword" placeholder="  请输入新密码" maxlength="30"
                                type="password"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="14" :offset="1">
                        <el-form-item label="确认密码" prop="newPasswords">
                            <el-input show-password v-model="passwords.newPasswords" placeholder="  请再次输入新密码" maxlength="30"
                                type="password"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="14" :offset="1">
                        <el-button type="primary" @click="changeForm()" size="medium" style="margin-bottom:20px">确 定</el-button>
                        <!-- <el-button type="dedfault" @click="cancel" size="mini">取 消</el-button> -->
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import { firstUpdatePwd } from "../../api/index.js";

export default{
    data() {
        const equalToPassword = (rule, value, callback) => {
            if (this.passwords.newPassword !== value) {
                callback(new Error("两次输入的密码不一致"));
            } else {
                callback();
            }
        };
        return {
            passwords: {
                oldPassword: '',
                newPassword: '',
                newPasswords: '',
            },
            rules: {
                oldPassword: [
                    {
                        required: true,
                        message: "原密码不能为空",
                        trigger: "blur"
                    }
                ],
                newPassword: [
                    {
                        pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,20}$/,
                        required: true,
                        message: "新密码不能为空,长度为 6 到 20 个字符，数字字母组合",
                        trigger: "blur"
                    },
                ],
                newPasswords: [
                    { required: true, validator: equalToPassword, trigger: "blur" }
                ],
            }
        }
    },
    methods: {
        reset() {
            this.passwords = {
                oldPassword: '',
                newPassword: '',
                newPasswords: '',
            }
        },
        changeForm() {
            this.$refs["passwords"].validate(valid => {
                if (valid) {
                    // updateUserPwd(this.passwords.oldPassword, this.passwords.newPassword).then(response => {
                        firstUpdatePwd({oldPassword:this.passwords.oldPassword,newPassword:this.passwords.newPassword}).then(response => {
                        if (response.code === 200) {
                            this.$message({
                                message: "修改成功",
                                type: "success",
                            });
                            this.reset();
                            setTimeout(() => {
                                this.$router.push("/index");
                            }, 1000);
                        } else {
                            this.$message({
                                message: response.msg,
                                type: "error",
                            });
                        }
                    });
                }
            });
        },
        // 取消按钮
        // cancel() {
        // this.reset();
        // this.$refs["form"].resetFields();
        // },
    },
}
</script>

<style scoped>
.mainbody {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  display: flex;
}
</style>
