const isDev=process.env.NODE_ENV === "development";
const baseUrl=isDev ? "http://localhost:9527" : location.protocol+"//"+location.host+"/prod-api";
// const serverBaseUrl=isDev ? "http://192.168.10.35:8096/" : location.protocol+"//"+location.host+"/Qilin";
const serverBaseUrl=isDev ? "http://47.122.0.182:8096/" : location.protocol+"//"+location.host+"/Qilin";
const uploadImageUrl=serverBaseUrl+"/system/user/importData";
const uploadXlsxUrl=serverBaseUrl+"/system/user/importTemplate";

export {
    isDev,
    baseUrl,
    uploadImageUrl,
    uploadXlsxUrl
}
