/**
 * 用户存储单点登录时所需token
 */

// 存储统一域名下的cookie
export function setValueCookie(key,value,time=7){
    var d = new Date()
    d.setTime(d.getTime()+(time*24*60*60*1000));
    document.cookie = key+"="+value+"; expires="+d.toUTCString()+";path=/;domain=.jsisi.cn;"; //线上
    // document.cookie = key+"="+value+"; expires="+d.toUTCString()+";path=/;"; //本地
}

// 获取统一域名下的cookie
// 根据特定的key值查询cookie中的value
export function getValueCookie(keyName){
    const Cookies=document.cookie;
    const startIndex=Cookies.indexOf(keyName);
    if(startIndex !== -1){
        let startValueIndex=startIndex+(keyName.length+1);
        let endIndex=Cookies.indexOf(";",startValueIndex);
        if(endIndex ===-1){
            endIndex=Cookies.length;
        }
        let value=decodeURI(Cookies.substring(startValueIndex,endIndex));
        return value;
    }else{
        return null;
    }
}                                                      

// 删除统一域名下的指定cookie
export function deleteValueCookie(keyName){
    var d=new Date();
    d.setTime(d.getTime()-1);
    document.cookie=keyName+"=; expires="+d.toUTCString()+";path=/;domain=.jsisi.cn;"; //线上
    // document.cookie=keyName+"=; expires="+d.toUTCString()+";path=/;"; //本地
}
