// 引入全局方法
import QilinUtils from "./utils/index.js";


// console.log(QilinDirectives);

const install=(Vue)=>{
    if(install.installed){ // 防止重复注册
        return;
    }
    install.installed=true;
    Vue.prototype.$QilinUtils=QilinUtils;
};

if(typeof window !== "undefined" && window.Vue){
    install(window.Vue);
}

export default install;
