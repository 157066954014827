import Vue from 'vue';
import App from './App.vue';
import router from "./router/index.js";
import store from "./store/index.js";

Vue.config.productionTip = false;

// 引入element ui框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入全局公用样式
// import "./assets/scss/common.scss";

// 引入EDGQiLin 全局组件和方法
import Qilin from "./global/index.js";
Vue.use(Qilin);

// 引入打印插件
import Print from "vue-print-nb";
Vue.use(Print);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
