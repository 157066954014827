<template>
  <div class="mainpage">
    <div class="mainbody">
      <div class="navmenu" v-show="nav">
        <div class="ictlogo" @click="fleshlist()">
          <div class="Ictlogoimg">
            <img src="../../assets/images/Group 3671.png" alt="">
          </div>
          <div class="Ictword">
            ICT
          </div>
        </div>
        <div class="eluinav">
          <div style="width: 101%;overflow: hidden;">
            <el-row class="tac">
              <el-col :span="24">
                <!-- <el-menu default-active="2" background-color="#fff" text-color="#000" class="el-menu-vertical-demo">
                  <el-menu-item v-for="item in deptOptions[0].children" :index="item.id" :key="item.id.toString()"
                    @click="navigationBars(item.id, item.label)">
                    <div class="departmentlogo" style="float:left;margin-right:10px" v-show="isactive">
                      <img src="../../assets/images/Group 8532.png" alt="">
                    </div>
                    {{ item.label }}
                  </el-menu-item>
                </el-menu> -->
                <el-menu default-active="2" background-color="#fff" text-color="#000">
                  <template v-for="item in deptOptions[0].children">

                    <el-submenu :index="item.id" :key="item.id" v-if="item.children">
                      <template slot="title">
                        <div @click="navigationBars(item.id, item.label, $event, item)">{{ item.label }}</div>
                      </template>
                      <template v-for="node in item.children">

                        <el-submenu :index="node.id" :key="node.id" v-if="node.children">
                          <template slot="title">
                            <div @click="navigationBars(node.id, node.label, $event)">{{ node.label }}</div>
                          </template>
                          <!-- <template v-if="node">
                            <el-menu-item v-for="joint in node.children" :index="joint.id" :key="joint.id.toString()"
                              @click="navigationBars(joint.id, joint.label, $event)">
                              {{ joint.label }}
                            </el-menu-item>
                          </template> -->
                          <template v-for="joint in node.children">

                            <el-submenu :index="joint.id" :key="joint.id" v-if="joint.children">
                              <template slot="title">
                                <div @click="navigationBars(joint.id, joint.label, $event)">{{ joint.label }}</div>
                              </template>
                              <template v-if="joint">
                                <el-menu-item v-for="point in joint.children" :index="point.id" :key="point.id.toString()"
                                  @click="navigationBars(point.id, point.label, $event)">
                                  {{ point.label }}
                                </el-menu-item>
                              </template>
                            </el-submenu>

                            <el-menu-item v-if="!joint.children" :index="joint.id" :key="joint.id"
                              @click="navigationBars(joint.id, joint.label, $event)">
                              {{ joint.label }}
                            </el-menu-item>
                          </template>
                        </el-submenu>

                        <el-menu-item v-if="!node.children" :index="node.id" :key="node.id"
                          @click="navigationBars(node.id, node.label, $event)">
                          {{ node.label }}
                        </el-menu-item>
                      </template>
                    </el-submenu>

                    <el-menu-item v-if="!item.children" :index="item.id" :key="item.id"
                      @click="navigationBars(item.id, item.label, $event)">
                      {{ item.label }}
                    </el-menu-item>
                  </template>
                </el-menu>

              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="maintable">
        <!-- 顶部操作栏 -->
        <div class="topmenu">
          <!-- 左边组+人数 -->
          <div class="leftpart">
            <div>{{ depName }}</div>
            <div>{{ paginationsObj.total }}人</div>
          </div>
          <div class="rightpart">
            <!-- 批量删除 -->
            <div class="delete" @click="deleteData()" v-show="sfyz">
              <i class="el-icon-delete"></i>批量删除
            </div>
            <!-- 导出 -->
            <div class="exportment" @click="exportButton()" v-show="sfyz">
              <i class="el-icon-upload2"></i>导出
            </div>
            <!-- 导入 -->
            <div class="importment" @click="importButton()" v-show="sfyz">
              <i class="el-icon-download"></i>导入
            </div>
            <!-- 新增 -->
            <div class="newadd" @click="insertButton()" v-show="sfyz">
              <i class="el-icon-plus"></i>新增
            </div>

            <!-- 搜索框 -->
            <div class="ipt">
              <input type="text" name="" id="" placeholder="请输入姓名/工号/手机号" v-model="queryParams.commonParam" 
                @keyup.enter="handleQuery"
                @keyup="cleanfocus">
                <!-- onchange="cleanfocus()" -->
              <i class="el-icon-error" @click="clean()" v-show="cleanbutton"></i>
              <span>|</span>
              <i class="el-icon-search" @click="handleQuery()"></i>
            </div>
          </div>
        </div>
        <!-- 表格 -->
        <div class="eluitable">
          <el-table :data="userList" height="calc(100% - 120px)" tooltip-effect="dark"
            @selection-change="handleSelectionChange" v-loading="loading" :class="[!sfyz ? 'normal' : '']"
            @row-click="hang">
            <!-- style="width: 100%" -->
            <el-table-column type="selection" width="55" v-if="sfyz">
            </el-table-column>
            <el-table-column prop="nickName" label="姓名" align="left">
              <template slot-scope="scope">
                <div style="display: flex;">
                  <div class="surname" :style="{ backgroundColor: colors[Math.floor(Math.random() * colors.length)] }">
                    {{
                      scope.row.nickName.slice(0, 1)
                    }}</div>
                  <div class="blue-font-color fullname">{{
                    scope.row.nickName
                  }}</div>
                  <!-- @click="detail(scope.row)" -->
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="工号" align="left">
            </el-table-column>
            <el-table-column prop="phonenumber" label="手机" align="left">
            </el-table-column>
            <el-table-column label="职务" align="left" prop="userName">
              <template slot-scope="scope">{{ scope.row.position }}</template>
            </el-table-column>
            <el-table-column prop="email" label="邮箱" align="left">
            </el-table-column>
            <el-table-column label="操作" align="left" v-if="sfyz" width="300px">
              <template slot-scope="scope">
                <el-button size="mini" type="text" @click.stop="deleteData(scope.row)"
                  style="font-size: 14px;padding: 2px 8px 1px 8px;background-color: #E7F4FF;font-weight: 400;">删除
                </el-button>
                <el-button @click.stop="updateButton(scope.row)" type="text" size="mini"
                  style="font-size: 14px;padding: 2px 8px 1px 8px;background-color: #E7F4FF;font-weight: 400;">
                  修改
                </el-button>
                <el-button @click.stop="ckxq(scope.row)" type="text" size="mini"
                  style="font-size: 14px;padding: 2px 8px 1px 8px;background-color: #E7F4FF;font-weight: 400;">
                  查看详情
                </el-button>
                <el-button @click.stop="czmm(scope.row)" type="text" size="mini"
                  style="font-size: 14px;padding: 2px 8px 1px 8px;background-color: #E7F4FF;font-weight: 400;">
                  重置密码
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :current-page="paginationsObj.currentPage" :page-sizes="paginationsObj.pageSizes"
            :page-size="paginationsObj.pageSize" :total="paginationsObj.total" :layout="paginationsObj.layout"
            background @current-change="handleCurrentPage" @size-change="handleSizeChange"></el-pagination>
          <el-dialog title="查看详情" :visible.sync="dialogTableVisible" width="770px" top="50vh" :show-close="false">
            <div class="ckxqdiv">
              <div>
                <span style="color: #262626;">姓名：</span><span style="color: #595959">{{
                  nowData.nickName
                }}</span>
              </div>
              <div v-if="nowData.deptName">
                <span style="color: #262626;">部门：</span><span style="color: #595959">{{
                  nowData.deptName
                }}</span>
              </div>
              <div v-else>
                <span style="color: #262626;">部门：</span><span style="color: #595959">
                </span>
              </div>
              <div>
                <span style="color: #262626;">岗位：</span><span style="color: #595959">{{
                  nowData.position
                }}</span>
              </div>
              <div>
                <span style="color: #262626;">手机号码：</span><span style="color: #595959">{{
                  nowData.phonenumber
                }}</span>
              </div>
              <div>
                <span style="color: #262626;">邮箱：</span><span style="color: #595959">{{
                  nowData.email
                }}</span>
              </div>
              <div>
                <span style="color: #262626;">工号：</span><span style="color: #595959">{{
                  nowData.userName
                }}</span>
              </div>
              <div>
                <span style="color: #262626;">职务：</span><span style="color: #595959">{{
                  nowData.postName
                }}</span>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogTableVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
            </span>
          </el-dialog>
          <!-- 重置密码 -->
          <!-- <el-dialog title="重置密码" :visible.sync="resetForm.czmmVisible" width="770px" top="50vh">
            <el-form ref="resetForm" :model="resetForm" :rules="rules">
              <el-form-item label="重置密码" prop="password">
                <el-input v-model="resetForm.password" placeholder=" 请输入密码" maxlength="30" />
              </el-form-item>
            </el-form>
            <div slot="footer">
            <el-button type="primary" @click="resetPwd" size="mini">确 定</el-button>
            <el-button type="dedfault" @click="cancel" size="mini">取 消</el-button>
          </div>
          </el-dialog> -->
          <!-- 点击姓名显示信息 -->
          <el-dialog title="" :visible.sync="dialogDetail" width="480px" top="50vh" class="informationmain" :show-close="false">
            <div class="informationtop">
              <div class="photo">
                {{ this.surname }}
              </div>
              <div class="introduce">
                <div class="name">
                  {{ nowData.nickName }}
                </div>
                <div class="infodepartment">
                  <div class="pic">
                    <img src="../../assets/images/Group 8548@2x.png" alt="">
                  </div>
                  ICT-{{ nowData.deptName }}
                </div>
              </div>
            </div>
            <div class="informationbottom">
              <div>
                <span class="name">工号：&nbsp;&nbsp;</span><span class="data">{{ nowData.userName }}</span>
              </div>
              <div>
                <span class="name">手机：&nbsp;&nbsp;</span><span class="data">{{ nowData.phonenumber }}</span>
              </div>
              <div>
                <span class="name">邮箱：&nbsp;&nbsp;</span><span class="data">{{ nowData.email }}</span>
              </div>
              <div>
                <span class="name">职务：&nbsp;&nbsp;</span><span class="data">{{ nowData.postName }}</span>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogDetail = false" class="detailbut">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <!-- 新增和修改通讯录人员数据对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body top="50vh" :show-close="false">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="mini">
            <!-- <el-form label-width="80px"> -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="姓名" prop="nickName">
                  <el-input v-model="form.nickName" placeholder="  请输入联系人姓名" maxlength="30" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="部门" prop="deptId">
                  <el-cascader 
                    v-model="form.deptId"
                    :props="defaultParams"
                    :options="deptOptions[0].children"
                    @change="handleChange"
                    placeholder=" 请选择归属部门"
                    >
                  </el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号码" prop="phonenumber">
                  <el-input v-model="form.phonenumber" placeholder="  请输入手机号码" maxlength="11" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="form.email" placeholder="  请输入邮箱" maxlength="50" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="岗位" prop="position">
                  <el-input v-model="form.position" placeholder="  请输入岗位" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工号" prop="userName">
                  <el-input v-model="form.userName" placeholder="  请输入工号" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="职位" prop="postId">
                  <el-select v-model="form.postId" placeholder=" 请选择职位">
                    <el-option v-for="item in postOptions" :key="item.id" :label="item.label" :value="item.id">
                    </el-option>
                  </el-select>
                  <!-- @select="node => cliManager(node)" -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户类型" prop="number">
                  <el-select v-model="form.employeeType" placeholder="用户类型">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"
                      :change="states()">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer">
            <el-button type="primary" @click="submitForm" size="mini">确 定</el-button>
            <el-button type="dedfault" @click="cancel" size="mini">取 消</el-button>
          </div>
        </el-dialog>
        <!-- 上传对话框新 -->
        <el-dialog :title="upload.title" :visible.sync="upload.open" width="30%" append-to-body top="50vh" :show-close="false">
          <el-upload class="upload-demo" :drag="drag" action="/Qilin/system/user/importData" multiple :auto-upload="false"
            :on-change="changeXlsx" :file-list="fileList">
            <!--  show-file-list="showFiles" -->
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" style="width: 100%;">拖拽到此或<em>点击上传表格</em>
              <p style="font-size:14px;color:#AFAFAF">（仅支持上传格式为xls、xlsx的文件）</p>
            </div>
            <div class="el-upload__tip" slot="tip" style="font-size:14px ;">点击下载导入表格模板：<span
                style="color: #319CFF;cursor: pointer;font-size: 14px;" @click="importTemp()">员工批量导入模板.xls</span></div>
          </el-upload>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="shangchuan()">确 定</el-button>
            <el-button @click="closeshangchuan()">取 消</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  searchData,
  deptTreeSelect,
  addUser,
  listUser,
  getUser,
  updateUser,
  deleteUser,
  postSelect,
  getTemplate,
  exportTable,
  importExcelData,
  getUserProfile,
  resetPwd
} from "../../api/index.js";
// import axios from "axios";

export default {
  inject: ["uploadImageUrl", "uploadXlsxUrl"],
  data() {
    return {
      defaultParams: {
        emitPath:false,
        label: 'label',
        value: 'id',
        children: 'children'
     },
      // 重置密码
      // resetForm:{
      // czmmVisible: false,
      password: "123456",
      // },
      cleanbutton: false,
      drag: true,
      sfyz: true,
      loading: false,
      noactive: true,
      isactive: false,
      // 姓氏
      surname: "",
      nav: true,
      lefthide: true,
      rightshow: false,
      deptId: null,
      deptIds: [],
      user: {},
      tablewidth: {
        name: "150",
        phone: "150",
        workid: "150",
        email: "180",
        doing: "480",
      },
      contentwidth: "1200px",
      closewidth: "78%",
      paginationsObj: {
        // 表格分页配置项
        currentPage: 1, //当前页
        pageSize: 20, //当前每页数量
        pageSizes: [10, 20, 30], //每页显示多少条选项集
        total: 0, //表格总数量
        layout: "total,prev,pager,next,sizes,jumper",
      },
      // 上传的显示列表
      fileList: [],
      // showFiles: false,
      //弹出窗标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 用户表格数据
      userList: null,
      // 部门树选项
      deptOptions: [{ children: [] }],
      // 职位树选项
      postOptions: [],
      options: [
        {
          value: '1',
          label: 'ICT员工'
        },
        {
          value: '2',
          label: '自有员工'
        },
      ],
      // 表单参数
      form: {
        //编号
        // userId: '',
        //工号
        userName: "",
        //姓名
        nickName: "",
        //岗位
        position: "",
        //部门
        deptId: "",
        //职位
        postId: "",
        //手机号
        phonenumber: "",
        //邮箱
        email: "",
        // 所属部门
        depName: "",
        // 用户状态
        // num: '1',
        employeeType: '1',
      },
      rules: {
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
            required: true,
          },
        ],
        userName: [
          {
            pattern: /^\d{6,11}$/,
            message: "请输入6~11位的正确工号",
            trigger: "blur",
            required: true,
          },
        ],
        phonenumber: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
            required: true,
          },
        ],
        nickName: [
          {
            pattern: /^[\u4e00-\u9fa5]{0,}$/,
            message: "请输入正确的姓名",
            trigger: "blur",
            required: true,
          },
        ],
        deptId: [
          {
            message: "请选择部门",
            required: true,
          },
        ],
        postId: [
          {
            message: "请选择职位",
            required: true,
          },
        ],
      },
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 100,
        userName: undefined,
        nickName: undefined,
        phonenumber: undefined,
        commonParam: "",
      },
      nowData: "",
      multipleSelection: [],
      dialogVisible: false,
      dialogDetail: false,
      dialogTableVisible: false,
      formLabelWidth: "120px",
      person: "",
      namemessage: "",
      rightNav: true,
      arrowiconleft: true,
      arrowiconright: false,
      colors: ["#4BB859", "#148EFF", "#417EF7", "#F5B134", "#9078D4"],
      depName: "ICT",
      file: null,
    };
  },
  created() {
    // this.form.num = this.options[0].value;
    // 权限判断
    if (sessionStorage.getItem('isAdmin') == "false" || sessionStorage.getItem('isAdmin') == "undefined" || sessionStorage.getItem('isAdmin') == null) {
      this.sfyz = false
    } else if (sessionStorage.getItem('isAdmin') == "true") {
      this.sfyz = true
    }
    console.log(sessionStorage.getItem('isAdmin'));
    this.getDeptTree();
    this.postTree();
    this.getList();
    this.getUser();
  },
  watch: {

  },
  computed: {},
  methods: {
    // 这里获取筛选框内的值
    handleChange(value) {
      console.log('value',value)
    },
    states() {
      // if (this.form.num == "1") {
      if (this.form.employeeType == "1") {
        this.rules.email[0].required = true;  // required 改成true则校验，false 为不校验。
        this.rules.userName[0].required = true;
        this.rules.postId[0].required = true;
        this.rules.nickName[0].required = true;

      } else {
        this.rules.email[0].required = false;
        this.rules.email[0].pattern = '';
        this.rules.deptId[0].required = false;
        this.rules.postId[0].required = false;
      }
    },

    cleanfocus(e) {
      // console.log(e.target.value);
      if (e.target.value) {
        this.cleanbutton = true
      } else {
        this.cleanbutton = false
      }
    },
    cleanblur() {
      this.cleanbutton = false
    },
    fleshlist() {
      this.deptId = "";
      this.getList(this.deptId);
      this.depName = "ICT";
      this.$router.go(0);
    },
    showhide() {
      if (this.nav == true) {
        this.nav = false;
        this.lefthide = false;
        this.rightshow = true;
      } else if (this.nav == false) {
        this.nav = true;
        this.lefthide = true;
        this.rightshow = false;
      }
    },
    getUser() {
      getUserProfile().then((response) => {
        this.user = response.data;
      });
    },
    // 点击切换页码事件监听
    handleCurrentPage(value) {
      this.paginationsObj.currentPage = value;
      this.getList(this.deptId, this.queryParams.commonParam);
    },
    // 点击切换每页数量事件监听
    handleSizeChange(value) {
      this.paginationsObj.pageSize = value;
      this.getList();
    },
    detail(i) {
      this.nowData = [];
      this.nowData = i;
      this.dialogDetail = true;
      this.surname = i.nickName.slice(0, 1);
      this.nowData.deptName = i.dept.deptName;

    },
    ckxq(i) {
      this.nowData = i;
      // this.nowData.push(i);
      if (i.dept) {
        this.nowData.deptName = i.dept.deptName;
      }
      this.dialogTableVisible = true;
      // console.log(this.nowData[0].nickName)
    },
    // 打开重置密码弹窗
    czmm(row) {
      // this.czmmVisible = true;
      const userId = row.userId;
      console.log(userId);
      console.log(this.password);
      this.$confirm("是否确认重置密码？", "重置密码")
        .then(function () {
          // console.log(1111);
          return resetPwd({ userId: userId, password: '123456' });
        })
        .then(() => {
          // this.getList();
          this.$message({
            type: 'success',
            message: '重置成功!'
          });
        })
        .catch(() => { });
    },
    hang(row) {
      this.nowData = row;
      console.log('nd',this.nowData);
      if (row.dept) {
        this.nowData.deptName = row.dept.deptName;
        // console.log('row',row);
        this.surname = row.nickName.slice(0, 1);
      }
      this.dialogDetail = true;
    },
    //选择的行
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.multiple = !val.length;
      this.ids = val.map((item) => item.userId);
    },
    //导航
    navigationBars(x, name, e, item) {
      console.log("点击菜单", e, item)
      if (e.target) {
        // console.log(document.querySelectorAll(".el-menu-item"));
        // console.log(document.querySelectorAll(".el-submenu__title"));
        document.querySelectorAll(".el-menu-item").forEach((item) => {
          // console.log(item)
          item.classList.remove("is-active");
        })
        document.querySelectorAll(".el-submenu__title").forEach((item) => {
          // console.log(item)
          item.classList.remove("is-active");
        })
        e.target.parentNode.classList.add("is-active");
      } else {
        document.querySelectorAll(".el-submenu__title").forEach((item) => {
          // console.log(item)
          item.classList.remove("is-active");
        })
      }
      this.depName = name;
      this.deptId = x;
      // listUser({ pageNum: this.paginationsObj.currentPage, pageSize: this.paginationsObj.pageSize, deptId: x }).then(res => {
      listUser({
        pageNum: 1,
        pageSize: this.paginationsObj.pageSize,
        deptId: x,
      })
        .then((res) => {
          this.userList = res.rows;
          this.paginationsObj.total = res.total;
        })
        .catch();
    },

    /** 搜索按钮操作 */
    handleQuery() {
      // this.queryParams.pageNum = 1;
      searchData({
        commonParam: this.queryParams.commonParam,
        pageNum: this.paginationsObj.currentPage,
        pageSize: this.paginationsObj.pageSize,
      })
        .then((response) => {
          this.paginationsObj.total = response.total;
          this.userList = response.rows;
        })
        .catch((err) => console.log(err));
    },

    /** 查询用户列表 */
    getList(deptId, commonParam) {
      this.loading = true;
      listUser({
        pageNum: this.paginationsObj.currentPage,
        pageSize: this.paginationsObj.pageSize,
        deptId: deptId,
        commonParam: commonParam
      }).then((response) => {
        this.paginationsObj.total = response.total;
        this.userList = response.rows;
        console.log('this.userList', this.userList);
        this.loading = false;
      });
    },

    /** 查询部门下拉树结构 */
    getDeptTree() {
      deptTreeSelect().then(response => {
        this.deptOptions = response.data;
        function getData(arr) {
          arr.forEach((item) => {
            if (item.children) {
              item.id = item.id + "";
              getData(item.children);
              var node = item.children
              if (node.children) {
                node.id = node.id + "";
                getData(node.children);
              } else {
                node.id = node.id + "";
              }
            } else {
              item.id = item.id + "";
            }
          });
        }
        getData(this.deptOptions);
        console.log("===", this.deptOptions)
      });
    },



    /** 查询职位下拉树结构 */
    postTree() {
      postSelect().then((response) => {
        this.postOptions = response.posts.map((item) => {
          return {
            id: item.postId,
            label: item.postName,
          };
        });
      });
    },
    //新增按钮
    insertButton() {
      this.reset();
      // getUser().then((response) => {
      this.form.deptId = this.deptId;
      this.open = true;
      this.title = "添加用户";
      // this.form.num = this.options[0].value;
      // });
    },
    /** 修改按钮操作 */
    updateButton(row) {
      this.reset();
      const userId = row.userId || this.ids;
      getUser(userId).then((response) => {
        this.form = response.data;
        // this.form=Object.assign(this.form,response.data);
        console.log(this.form)
        console.log('-deptId-',this.form.deptId)
        if (response.data.dept) {
          // this.form.deptId = response.data.dept.deptName;
          this.form.deptId = response.data.deptId+"";
        }
        //  else {
        //   this.form.deptId = response.data.deptId;
        // }
        // console.log(this.form.deptId)
        this.open = true;
        this.title = "修改用户";
      });
    },
    /** 删除按钮操作 */
    deleteData(row) {
      const userId = row ? row.userId : this.ids;
      this.$confirm("是否确认删除该数据？", "删除员工")
        .then(function () {
          return deleteUser(userId);
        })
        .then(() => {
          this.getList(this.deptId);
          this.msgSuccess("删除成功");
        })
        .catch(() => { });
    },

    /** 导出按钮操作 */
    exportButton() {
      exportTable({
        commonParam: this.queryParams.commonParam,
        deptId: this.deptId,
      }).then((result) => {
        var blob = new Blob([result], {
          type: "application/vnd.ms-excel;charset=UTF-8",
        });
        if (window.navigator.msSaveBlob) {
          //没有此判断的话，ie11下的导出没有效果
          //window.navigator.msSaveBlob(blob, unescape(res.headers.filename.replace(/\\u/g, '%u')));
          window.navigator.msSaveBlob(blob, "通讯录");
        } else {
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = "通讯录";
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    },

    /** 下载模板操作 */
    importTemp() {
      getTemplate().then((result) => {
        var blob = new Blob([result], {
          type: "application/vnd.ms-excel;charset=UTF-8",
        });
        if (window.navigator.msSaveBlob) {
          //没有此判断的话，ie11下的导出没有效果
          //    window.navigator.msSaveBlob(blob, unescape(res.headers.filename.replace(/\\u/g, '%u')));
          window.navigator.msSaveBlob(blob, "通讯录");
        } else {
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = "通讯录";
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    },

    // 点击导入按钮事件监听
    // importButton0() {
    //   this.$refs['upload'].$children[0].$refs.input.click();
    // },
    /** 导入按钮操作 */
    importButton() {
      this.fileList = []
      this.upload.title = "用户导入";
      this.upload.open = true;
    },
    // 自定义导入事件监听
    requestXlsx(param) {
      console.log(param);
    },
    // 导入文件状态改变事件监听
    changeXlsx(file) {
      const xlsxType = file.name.split(".").pop();
      if (!["xlsx", "xls"].includes(xlsxType)) {
        this.$message({
          message: "请上传正确格式的excel文档",
          type: "warning",
        });
      } else {
        this.file = new FormData();
        this.file.append("file", file.raw);
      }
      // this.$refs["upload"].clearFiles();
    },
    shangchuan() {
      // let formData = new FormData();
      importExcelData(this.file).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.upload.open = false;
          // this.$refs["upload"].clearFiles();
          // this.getTableData();
          // this.getTableColumn();
        } else {
          this.fileList = []
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    closeshangchuan() {
      this.upload.open = false;
      this.fileList = []
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
      this.$refs["form"].resetFields();
    },
    // 表单重置
    reset() {
      this.form = {
        //编号
        // userId: undefined,
        //工号
        userName: undefined,
        //姓名
        nickName: undefined,
        //岗位
        position: undefined,
        //部门
        deptId: undefined,
        //职位
        postId: undefined,
        //手机号
        phonenumber: undefined,
        //邮箱
        email: undefined,
        // num: "1",
        employeeType: '1',
      };
      // this.resetForm("form");
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // if (this.form.userId != undefined) {
            if (this.form.userId) {
              // console.log('deptId',this.form.deptId);
            // this.form.deptId = this.form.deptId[this.form.deptId.length-1]
            console.log('deptId',this.form.deptId);
            updateUser(this.form).then((response) => {
              if (response.code === 200) {
                // alert("修改成功");
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.open = false;
                this.getList();
              } else {
                // alert("修改失败，工号"+this.form.userName+"已存在");
                this.$message({
                  message:
                    "修改失败，工号" +
                    this.form.userName +
                    "已存在",
                  type: "error",
                });
                return false;
              }
            });
          } else {
            Reflect.deleteProperty(this.form, "userId");
            // this.form.deptId = this.form.deptId[this.form.deptId.length-1]
            addUser(this.form).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.open = false;
                this.getList(this.deptId);
              } else {
                this.$message({
                  // message: "新增失败，请检查输入内容是否正确",
                  message: response.msg,
                  type: "error",
                });
                return false;
              }
              // alert("新增成功");
              // this.$modal.msgSuccess("新增成功");
            });
          }
        }
      });
    },
    clean() {
      this.queryParams.commonParam = "";
      this.cleanbutton = false

    },
    arrowopenclose() {
      if (this.arrowiconleft == true) {
        this.rightNav = false;
        this.arrowiconleft = false;
        this.arrowiconright = true;
        this.closewidth = "98%";
        this.contentwidth = "1500px";
        this.tablewidth = {
          name: "170",
          phone: "170",
          workid: "170",
          email: "230",
          doing: "520",
        };
      } else if (this.arrowiconleft == false) {
        this.rightNav = true;
        this.arrowiconleft = true;
        this.arrowiconright = false;
        this.closewidth = "78%";
        this.contentwidth = "1200px";
        this.tablewidth = {
          name: "100",
          phone: "100",
          workid: "100",
          email: "150",
          doing: "420",
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  transform: translateY(-50%);
}

.mainpage {
  height: 100%;
}

.mainbody {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  background-color: #fff;
  display: flex;
}

.showhide {
  width: 20px;
  height: 100px;
  line-height: 100px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: -17px;
}

.mainbody>.navmenu {
  width: 280px;
  min-width: 250px;
  height: 100%;
  /* background-color: yellow; */
  border-right: 1px solid #E9E9E9;
}

.mainbody>.navmenu>.ictlogo {
  width: 78%;
  height: 10%;
  margin: 0 auto;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.mainbody>.navmenu>.ictlogo>.Ictlogoimg {
  width: 32px;
  height: 32px;
  float: left;
  margin-top: 10%;
}

.mainbody>.navmenu>.ictlogo>.Ictlogoimg>img {
  width: 100%;
  height: 100%;
}

.mainbody>.navmenu>.ictlogo>.Ictword {
  height: 32px;
  line-height: 32px;
  float: left;
  margin-left: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 10%;
}

.mainbody>.navmenu>.eluinav {
  width: 90%;
  height: 88%;
  margin: 1% auto;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0px;
}

.mainbody>.maintable {
  flex: 1;
  height: 100%;
  padding: 0 20px;
  overflow: hidden;
  min-width: 800px;
}

.mainbody>.maintable>.topmenu {
  width: 100%;
  height: 67px;
}

.mainbody>.maintable>.topmenu>.leftpart {
  width: 20%;
  height: 67px;
  float: left;
  display: flex;
  align-items: center;
}

.mainbody>.maintable>.topmenu>.leftpart>div {
  font-size: 16px;
  font-weight: 700;
  font-family: Source Han Sans CN-Medium;
  padding: 0 5px;
}

.mainbody>.maintable>.topmenu>.leftpart>div:nth-child(1) {
  color: #262626;
}

.mainbody>.maintable>.topmenu>.leftpart>div:nth-child(2) {
  color: #595959;
}

.mainbody>.maintable>.topmenu>.rightpart {
  width: 80%;
  height: 67px;
  float: right;
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt {
  width: 256px;
  height: 30px;
  margin-top: 19px;
  float: right;
  border: 1px solid #d9d9d9;
  display: flex;
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt>input {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 12px;
  color: #000000 25%;
  text-indent: 16px;
  /* float: left; */
}

input::-webkit-input-placeholder {
  color: rgb(194, 189, 189);
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt>i {
  flex: 1;
  line-height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 6px;
  color: #d9d9d9;
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt>span {
  line-height: 26px;
  font-size: 18px;
  color: #d9d9d9;
}

.mainbody>.maintable>.topmenu>.rightpart>.newadd {
  width: 80px;
  height: 30px;
  margin: 19px 8px 0 24px;
  float: right;
  border-radius: 2px;
  background-color: #deefff;
  color: #47a6ff;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #47a6ff;
  cursor: pointer;
}

.mainbody>.maintable>.topmenu>.rightpart>.delete {
  width: 104px;
  height: 30px;
  margin: 19px 8px 0 16px;
  float: right;
  border-radius: 2px;
  background-color: #ffeeee;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ff4d4f;
  cursor: pointer;
}

.mainbody>.maintable>.topmenu>.rightpart>div>i {
  margin-right: 3px;
}

.mainbody>.maintable>.topmenu>.rightpart>.importment {
  width: 80px;
  height: 30px;
  float: right;
  margin: 19px 16px 0 8px;
  border-radius: 2px;
  background-color: #e7f8f2;
  color: #56cea3;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #56cea3;
}

.mainbody>.maintable>.topmenu>.rightpart>.exportment {
  width: 80px;
  height: 30px;
  margin-top: 19px;
  float: right;
  border-radius: 2px;
  background-color: #fef3dc;
  color: #faad14;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #faad14;
}

.mainbody>.maintable>.eluitable {
  /* width: 100%; */
  height: 100%;
}

.surname {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  /* border: 1px solid #000; */
  float: left;
}

.fullname {
  width: 55px;
  height: 28px;
  line-height: 28px;
  text-align: left;
  float: left;
  margin-left: 8px;
}
::v-deep .el-dialog .el-dialog__body{
  padding: 5px 20px;
}
.informationtop {
  width: 100%;
  height: 114px;
  border-bottom: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.photo {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  margin: 20px 20px 20px 40px;
  background-color: #4BB859;
  float: left;
  line-height: 64px;
  text-align: center;
  color: #fff;
  font-size: 30px;
}

.introduce {
  width: 150px;
  height: 22px;
  float: left;
}

.introduce>.name {
  height: 100%;
  margin-top: 27px;
  margin-bottom: 10px;
  line-height: 22px;
  font-size: 20px;
  text-align: left;
  font-weight: 500;
}

.introduce>.infodepartment {
  height: 100%;
  line-height: 22px;
  font-size: 16px;
  text-align: left;
}

.introduce>.infodepartment>.pic {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 5px;
}

.introduce>.infodepartment>.pic>img {
  width: 100%;
  height: 100%;
}

.informationbottom {
  width: 100%;
  height: 170px;
}

.informationbottom>p {
  width: 90%;
  margin: 15px auto;
  font-size: 18px;
  color: #000;
  text-indent: 20px;
}
.informationbottom>div{
  width: 90%;
  margin: 15px auto;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  text-indent: 20px;
}
.informationbottom>div>.data{
  width: 90%;
  margin: 15px auto;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  text-indent: 20px;
}
.detailbut {
  color: #000000;
}

.informationbottom>p:nth-child(1) {
  margin-top: 30px;
}

.el-menu-item.is-active {
  background-color: #e8f4ff !important;
  color: #148eff;
  font-weight: 600;
  font-size: 14px;
}

.ckxqdiv {
  height: 200px;
  border-top: 1px solid #E9E9E9;
  padding: 0px 10px 10px 10px;

}

.ckxqdiv>div {
  width: 50%;
  height: 50px;
  float: left;
  text-align: left;
  line-height: 50px;
  color: #000000 60%;
}

.ckxqdiv>div>span {
  font-size: 14px;
}

.el-menu-item:hover {
  background-color: #e8f4ff !important;
}

// ::v-deep .el-submenu.is-active > .el-submenu__title {
//   color: #409EFF !important
// }
// .el-menu-item:hover[data-v-f3d43bd6] {
//     background-color: #e8f4ff !important;
//     color: #148eff;
//     font-weight: 600;
//     font-size: 14px;
// }
::v-deep .el-submenu__title.is-active {
  background-color: #e8f4ff !important;
  color: #148eff !important;
  font-weight: 600;
  font-size: 14px;
}

::v-deep .el-table th.el-table__cell>.cell {
  font-weight: 500 !important;
}

::v-deep .cell {
  /* padding-left: 80px !important */
}

::v-deep .el-table.normal {
  >.el-table__header-wrapper {
    th:first-of-type>div {
      padding-left: 80px;
    }
  }

  >.el-table__body-wrapper {
    td:first-of-type>div {
      padding-left: 80px;
    }
  }
}

::v-deep .el-table tbody tr:hover>td {
  background-color: #e8f4ff !important;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::v-deep .el-table__body .el-table__row:hover {
  cursor: pointer; //鼠标变小手
}

::v-deep .el-upload {
  width: 100% !important;
}

::v-deep .el-upload-dragger {
  margin: 0 auto;
}

::v-deep .el-pagination {
  font-weight: 500;
}
</style>
