import Vue from "vue";
import VueRouter from "vue-router";
import mainPage from '../views/pRank/index.vue'
import myLogin from '../views/myLogin.vue'
import passChange from '../views/pRank/passChange.vue'
import retrievePassword from '../views/retrievePassword.vue'
// import getCookie from '../utils/request'
import { getValueCookie, setValueCookie } from "@/utils/cookie.js";
import { analysisToken } from "@/api/index.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
    // component:()=>import("@/views/homePage.vue"),
    // children:[
    //     {
    //         path:"pRank/index",
    //         name:"mainPage",
    //         component: mainPage
    //     },
    // ]
  },
  {
    path: '/myLogin',
    name: 'myLogin',
    // component: () => import("@/views/newLogin.vue")
    component: myLogin
  },
  {
    path: "/retrievePassword",
    name: "retrievePassword",
    component: retrievePassword,
    meta: { needLogin: false }
  },
  {
    path: '/',
    name: "homePage",
    component: () => import("@/views/homePage.vue"),
    children: [
      {
        path: "/index",
        name: "mainPage",
        component: mainPage
      },
      {
        path: "/passChange",
        name: "passChange",
        component: passChange
      },

    ]
  },
];

// console.log(process.env.BASE_URL);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
// 登录验证
// router.beforeEach((to, from, next) => {
//     if (to.path != "/myLogin") {
//         if (sessionStorage.getItem("token")) {
//             next()
//         } else {
//             //如未登录，返回登录页
//             next("/myLogin")
//         }
//     } else {
//         next()
//     }
// })

router.beforeEach((to, from, next) => {
  if (to.path !== "/myLogin") {
    if (getValueCookie("TX-token")) { //先判断有无通讯token
      next();
    }
    else { //若无通讯token
      if (to.path === "/retrievePassword") {
        next();
      }
      else if(getValueCookie("x-token")) { //判断有无x-token
        analysisToken({ xtoken: getValueCookie("x-token") }).then((res) => {
          // console.log(res);
          setValueCookie("TX-token", res.data.token);
          if (res.data.firstLogin == 0) {
            next("passChange");
          }
          next();
        }).catch((err) => {
          console.log(err);
          next();
        });
      } else {
        // next();
        next("myLogin");
      }
    }
  } else {
    next();
  }
});

export default router;
